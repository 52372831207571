<template>
  <button @click="sync">{{ text }}</button>
</template>

<script>
import DocAdmin from '@/store/data'

export default {
  props: {
    text: {
      type: String,
      default: 'Sync'
    },
    documentId: {
      type: String || Number,
      required: false,
      default: null
    },
    languageId: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    async sync() {
      try {
        let message = `Syncing reading plan for ${this.documentId || 'all documents'}`

        if (this.languageId) {
          message = `Syncing reading plan for document ${this.documentId} and language ${this.languageId}`
        }

        const proceed = confirm(message)

        if (!proceed) {
          return
        }
        
        if (this.languageId) {
          const response = await DocAdmin.sync.export.readingPlan({
            document_id: this.documentId,
            language_id: this.languageId
          })
        } else {
          const response = await DocAdmin.sync.export.readingPlans({
            document_id: this.documentId
          })
        }
      } catch (error) {
        console.error('Error syncing reading plan:', error)
      }
    }
  }
}
</script>