<template>
  <div v-if="allowRead(document.documenttypeid.name)">
    <DocumentItemsNavigation
      :key="document.documentid"
      :document-id="parseInt(document.documentid)"
      class="mb-2"
    />
    <debug v-if="debug">
      <template slot="sub-heading">DocumentHome</template>
      <template slot="info">
        document={{ document }}<br/>
        documentTranslations={{ documentTranslations }}<br/>
        dataStructure={{ dataStructure }}
      </template>
    </debug>
  </div>
  <v-alert v-else prominent type="error">
    Access is denied, you do not have the required groups to access this, your group: {{ groups }}
  </v-alert>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DocumentItemsNavigation from '@/components/documentItems/DocumentItemsNavigation'
import Debug from '@/components/common/Debug'

export default {
  name: 'DocumentHome',
  components: {
    DocumentItemsNavigation,
    Debug
  },
  computed: {
    ...mapState('documents', ['document', 'documentTranslations', 'dataStructure']),
    ...mapState('app', ['debug']),
    ...mapState('account', ['groups']),
    ...mapGetters('account', ['allowRead'])
  }
}
</script>