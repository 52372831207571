<template>
  <div>
    <v-expansion-panels v-model="panel" small flat accordion class="ma-0 pa-0">
      <v-expansion-panel class="ma-0 pa-0">
        <v-expansion-panel-header hide-actions color="red--lighten-1">
          <div class="text-caption">
            <v-icon color="primary" small>mdi-lightning-bolt</v-icon> Debug <slot name="sub-heading"></slot>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content small class="text-caption">
          <slot name="info"></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Debug',
  components: {
  },
  props: {},
  data: () => ({
    panel: null
  }),
  computed: {
    ...mapState('app', ['debug'])
  }
}
</script>