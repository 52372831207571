<template>
  <v-card flat tile class="mb-2">
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="8">
        <AuthorMultipleLookupField :selected="authors" @input="updateLocal($event)"/>
      </v-col>
      <v-col cols="6" md="4">
        <v-btn class="mx-2" fab small color="primary" @click="saveLocal" v-if="authorsChanged">
          <v-icon dark>
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    
    <debug v-if="debug">
      <template slot="sub-heading">DocumentAuthorsEditForm</template>
      <template slot="info" class="text-xs">
        authorsChanged={{ authorsChanged }}<br/>
        authorsLocal={{ authorsLocal }}
      </template>
    </debug>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import AuthorMultipleLookupField from '@/components/shared/AuthorMultipleLookupField'
import Debug from '@/components/common/Debug'

import _ from 'lodash'

export default {
  name: 'DocumentAuthorsForm',
  components: {
    AuthorMultipleLookupField,
    Debug
  },
  props: {
    documentAuthors: {
      type: Array
    },
    save: {
      type: Function,
      required: true
    },
    buttonText: {
      type: String,
      default: 'Save'
    }
  },
  data() {
    return {
      authorsLocal: [],
      authorsChanged: null
    }
  },
  created(){
    if (this.documentAuthors.length) {
      this.authorsLocal = _.cloneDeep(this.documentAuthors)
    }
  },
  computed: {
    ...mapState('app', ['debug']),
    authors() {
      return this.authorsLocal.map(({ personid }) => personid)
    }
  },
  methods: {
    updateLocal(e) {
      this.authorsChanged = e.map((p) => {
        return {
          documentid_id: Number(this.$route.params.documentid),
          personid_id: p.personid
        }
      })
    },
    saveLocal() {      
      this.save(this.authorsChanged)
    }
  }
}
</script>