<template>
  <v-dialog :value="true" persistent max-width="290">
    <v-card>
      <v-toolbar dark dense flat :color="barColor + ' darken-2'">
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-2">{{ message }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">No</v-btn>
        <v-btn color="red darken-1" text @click="$emit('delete')">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  components: { },
  props: {
    message: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    barColor: {
      type: String,
      default: 'blue'
    }
  }
}
</script>