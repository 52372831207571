<template>
  <v-autocomplete
    v-model="select"
    :items="entries"
    :search-input.sync="search"
    multiple
    cache-items
    chips
    item-text="englishname"
    item-value="personid"
    :label="label"
    placeholder="Start typing to Search"
    append-outer-icon="mdi-plus-circle"
    return-object
  >
  </v-autocomplete>
</template>
<script>
import DocAdmin from '@/store/data'
import configs from '@/configs'

export default {
  name: 'AuthorMultipleLookupField',
  props: {
    selected: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'Author(s)'
    }
  },
  data: () => ({
    select: null,
    entries: [],
    isLoading: false,
    search: '',
    count: null,
    connectionType: configs.connectionProperties.types
  }),
  watch: {
    select(value) {
      if (JSON.stringify(value) === JSON.stringify(this.selected)) { return }
      this.$emit('input', value);
    },
    search(query) {
      if (query && (!this.select || this.select.englishname !== query)) {
        this.querySelections(query)
      }
    }
  },
  created () {
    this.selected.forEach((a)=>{
      this.querySelections(a.englishname)
    })
    this.select = this.selected
  },
  methods: {
    getItemById(personObject) {
      return this.entries.find((item) => item.personid === personObject.personid)
    },
    querySelections(q) {
      this.isLoading = true

      DocAdmin.persons.list({ params: { search: q } }).then((res) => {
        const { count, results } = res.data

        this.count = count
        this.entries = results
      }).catch((err) => {
        console.error(`AuthorLookupField.search.error =${err}`)
      }).finally(() => (this.isLoading = false))
    },
    addNewPerson() {
      alert('yet to be implemented')
    }
  }
}
</script>