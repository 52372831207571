<template>
  <div v-if="readingTimeInMinutes">
    <v-icon small>
      mdi-timer-outline
    </v-icon>
    {{ readingTimeInMinutes }} {{ subtext }}
  </div>
</template>
<script>
export default {
  name: 'ReadingTime',
  props: {
    readingTimeInMinutes: {
      type: [Number,String],
      default: null
    },
    subtext: {
      type: String,
      default: 'min read'
    }
  }
}
</script>