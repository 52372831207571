<template>
  <v-dialog persistent :value="true" max-width="600">
    <v-card>
      <v-toolbar v-if="title" dark dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text v-show="!!text" class="pa-2 small">
        <pre>{{ text }}</pre>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'JsonDialog',
  props: {
    text: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>