<template>
  <v-card flat tile class="mb-2">
    <v-alert
      v-if="error.show"
      icon="mdi-alert-circle"
      dismissible
      text
      type="error"
    >
      {{ error.message }}
    </v-alert>

    <v-card-text>
      <ValidationObserver ref="document_form" tag="form">

        <ValidationProvider v-slot="{ errors }" name="englishtitle" rules="required|max:255">
          <v-text-field
            v-model="documentLocal.englishtitle"
            label="English Title"
            data-vv-name="englishtitle"
            :error-messages="errors"
          />
        </ValidationProvider>

        <v-row justify="start" no-gutters>
          <v-col cols="6">
            <ValidationProvider v-slot="{ errors }" name="documenttypeid" rules="required|max:255">
              <DocumentTypeLookupField
                v-model="dti"
                :value="documentLocal.documenttypeid"
                :error-messages="errors"
                name="documenttypeid"
                @input="updateLocal('documenttypeid', $event)"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <DataStructureLookupField
              :value="documentLocal.datastructureid"
              name="datastructureid"
              @input="updateLocal('datastructureid', $event)"
            />
          </v-col>
        </v-row>
        <v-btn @click="determineSave">{{ buttonText }}</v-btn>

      </ValidationObserver>
    </v-card-text>

    <debug v-if="debug">
      <template slot="sub-heading">DocumentEditForm</template>
      <template slot="info" class="text-xs">
        documentLocal={{ documentLocal }},<br/>
        document={{ document }}<br/>
        isNewRecord={{ isNewRecord }}
      </template>
    </debug>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, max, regex } from 'vee-validate/dist/rules'
import configs from '@/configs'
import Vue from 'vue'

import DocumentTypeLookupField from '@/components/shared/DocumentTypeLookupField'
import DataStructureLookupField from '@/components/shared/DataStructureLookupField'
import AuthorMultipleLookupField from '@/components/shared/AuthorMultipleLookupField'
import Debug from '@/components/common/Debug'

import _ from 'lodash'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: 'Phone number must be valid'
})

export default {
  name: 'DocumentForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    DocumentTypeLookupField,
    DataStructureLookupField,
    AuthorMultipleLookupField,
    Debug
  },
  props: {
    document: {
      type: Object
    },
    save: {
      type: Function
    },
    buttonText: {
      type: String,
      default: 'Save'
    }
  },
  data() {
    return {
      dti: null,
      connectionType: configs.connectionProperties.types,
      error: {
        show: false,
        message: null
      },
      changed: false,
      valid: false,
      documentLocal: {}
    }
  },
  created() {
    if (this.document && Object.keys(this.document).length) {
      this.documentLocal = _.cloneDeep(this.document)
    }
  },
  computed: {
    ...mapState('app', ['debug']),
    isNewRecord () {
      return !this.documentLocal.documentid
    }
  },
  methods: {
    ...mapActions('documents', ['createDocument']),
    updateLocal (field, value) {
      if (field === 'documenttypeid') {
        this.dti = value
      }
      console.log(`field=${field}, value=${value}`)
      Vue.set(this.documentLocal, field, value)
    },
    async saveDocument() {
      this.$refs.document_form.validate().then(async (isValid) => {
        if (!isValid) { 
          console.error(`DocumentForm.saveDocument form is not valid`)
          return
        }

        if ('documenttypeid' in this.documentLocal) {
          this.documentLocal.documenttypeid_id = this.documentLocal.documenttypeid.documenttypeid
        }
        
        if ('datastructureid' in this.documentLocal) {
          this.documentLocal.datastructureid_id = this.documentLocal.datastructureid.datastructureid
        }

        const documentId = await this.createDocument(this.documentLocal)
        this.$store.dispatch('app/setSnackBar', 'New Document added.')
        this.$router.push({ 
          name: 'document_edit', 
          params: {
            documentid: documentId.documentid
          }
        })
      })
    },
    saveProxy() {
      this.save(this.documentLocal)
    },
    determineSave() {
      if (this.isNewRecord) {
        this.saveDocument()
        return
      }
      this.saveProxy()
    }
  }
}
</script>