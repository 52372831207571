<template>
  <div>
    <v-card v-if="!data && assignedTerms" class="mx-auto" flat>
      <span v-for="(at, i) in assignedTerms" :key="at.termrelationshipid">
        <router-link :to="{ name: 'term_home', params: { termid: at.termid.termid } }">{{ at.termid.englishtitle }}</router-link>
        <span v-if="i+1 < assignedTerms.length">, </span>
      </span>
    </v-card>
    <div class="d-flex align-start" v-if="$store.state.app.editing && isRenderComponent('allowedTermTypes')">
      <div v-for="termType in isRenderComponent('allowedTermTypes')" :key="`att-${termType}`">
        <TermsField 
          :term-type="termType" 
          :source-id="data.documentitemid" 
          :source-connection-type-id="connectionType.documentItems"
          :compact="true"
        />
      </div>
    </div>
    <v-card v-if="(!($store.state.app.editing && isRenderComponent('allowedTermTypes')) && data && Object.keys(data).length)" tile flat>

      <div class="d-flex align-center">
        <div v-for="termType in Object.keys(data.terms)" :key="`ta-${termType}`">
          <v-chip 
            v-for="term in data.terms[termType]" 
            :key="`ta-${term.termid}`" 
            class="mr-1" 
            x-small
            @click="routeChip(term)"
          >
            <v-icon x-small left>
              {{ getTermTypeIcon(termType) }}
            </v-icon>
            {{ term.term }}
          </v-chip>
        </div>
      </div>
    </v-card>
    
    <debug v-if="debug">
      <template slot="sub-heading">TermsAssigned</template>
      <template slot="info">
        assignedTerms={{ assignedTerms }}<br/>
        sourceId={{ sourceId }}<br/>
        connectionTypeId={{ connectionTypeId }}<br/>
        termTypeId={{ termTypeId }}<br/>,
        dataTerms={{ data.terms }}
      </template>
    </debug>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import DocAdmin from '@/store/data'
import Debug from '@/components/common/Debug'
import types from '@/helpers/types'
import TermsField from '@/components/terms/TermsField'

export default {
  name: 'TermsAssigned',
  components: {
    Debug,
    TermsField
  },
  props: {
    data: {
      type: Object,
      required: false
    },
    sourceId: {
      type: Number,
      default: null
    },
    connectionTypeId: {
      type: Number,
      default: null
    },
    termTypeId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    assignedTerms: [],
    loading: true,
    types
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById']),
    ...mapGetters('documents', ['renderComponent']),
    ...mapState('app', ['debug'])
  },
  created() {
    if (this.data && Object.keys(this.data).length) { return }
    this.getTermsAssigned()
  },
  methods: {
    isRenderComponent (componentName) {      
      return this.renderComponent(this.data.documentitemtypeid, componentName)
    },
    routeChip(t) {
      return this.$router.push({
        name: 'term_home', 
        params: { termid: t.termid }
      })
    },
    getTermTypeIcon(t) {
      if ( !Object.keys(this.types.termTypes).includes(t.toLowerCase()) ) {
        return ''
      }
      return this.types.termTypes[t.toLowerCase()].icon
    },
    async getTermsAssigned() {

      const qparams = {
        sourceid: this.sourceId,
        source_connectiontypeid: this.connectionTypeId
      }

      if (this.termTypeId) {
        qparams.termid__termtypeid_id = this.termTypeId
      }

      const response = await DocAdmin.termRelationships.list({ params: qparams })
      this.assignedTerms = response.data.results
      this.loading = false
    }
  }
}
</script>