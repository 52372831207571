<template>
  <v-autocomplete
    :value="valueLocal"
    :items="documentTypes"
    :loading="loading"
    :label="label"
    :name="name"
    :error-messages="errorMessages"
    :required="required"
    tile
    color="blue-grey lighten-2"
    item-text="name"
    item-value="documenttypeid"
    :hint="hint"
    v-on="listeners"
    return-object
  >
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DocumentTypeLookupField',
  model: {
    prop: "selected",
    event: "change",
  },
  props: {
    value: {
      type: [Object, String],
      default: null
    },
    label: {
      type: String,
      default: 'Document Type'
    },
    hint: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'documenttypeid'
    },
    errorMessages: {
      type: Array,
      default: []
    },
    required: {
      type: Boolean,
      default: false,
      description: "Native input required attribute",
    }
  },
  data() {
    return {
      valueLocal: this.value,
      loading: true
    }
  },
  computed: {
    ...mapState('documents', ['documentTypes']),
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => { 
          if (!e) { return }
          this.$emit("input", e)
        }
      }
    }
  },
  watch: {
    documentTypes: {
      deep: true,
      handler: function(a) {
        this.setValue()
      }
    }
  },
  created() {
    if (this.documentTypes && this.documentTypes.length >= 1) { 
      this.loading = false
      return 
    }
    this.loadData()
  },
  mounted() {
    this.setValue()
  },
  methods: {
    setValue() {
      if (this.$route.query.documenttype && !this.value && this.documentTypes) {
        this.setValueFromQuery(this.$route.query.documenttype)
      }
    },
    /**
     * 
     * @param {String} documentTypeName 
     */
    setValueFromQuery(documentTypeName) {
      this.valueLocal = this.documentTypes.find((dt) => dt.name.toLowerCase() === documentTypeName.toLowerCase())
      this.$emit("input", this.valueLocal)
      console.log(`DocumentTypeLookupField.setValueFromQuery documentTypeName=${documentTypeName}, this.valueLocal=${JSON.stringify(this.valueLocal)}`)
    },
    async loadData() {
      await this.$store.dispatch('documents/getDocumentTypes')
      this.loading = false
    }
  }
}
</script>