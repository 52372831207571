<template>
  <v-combobox
    v-model="select"
    :loading="isLoading"
    :items="items" 
    :label="termType" 
    :hide-no-data="!search"
    :search-input.sync="search"
    :small-chips="compact"
    :solo="compact"
    :dense="compact"
    item-text="englishtitle"
    item-value="termid"
    hint="documentItems wont be updated until refresh."
    flat
    multiple 
    chips
    :prepend-inner-icon="termTypeIcon"
    append-outer-icon="mdi-plus-circle"
    @click:append-outer="addNewTerm()"
  >
    <template v-slot:selection="data">
      <v-chip
        v-if="data.item.englishtitle"
        :key="data.item.termid"
        v-bind="data.attrs"
        :input-value="data.select"
        close
        :x-small="compact"
        @click="data.select"
        @click:close="removeItem(data.item)"
      >
        {{ data.item.englishtitle }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import DocAdmin from '@/store/data'
import { mapGetters, mapState } from 'vuex'
import types from '@/helpers/types'
import configs from '@/configs'

export default {
  name: 'TermsField',
  props: {
    compact: {
      type: Boolean,
      default: false
    },
    termType: {
      type: String,
      default: null
    },
    sourceId: {
      type: [Number, String],
      default: null
    },
    sourceConnectionTypeId: {
      type: [Number, String],
      default: null
    }
  },
  data: () => ({
    genericTypes: types,
    isLoading: false,
    select: [],
    items: [],
    search: null,
    termRelationships: {}
  }),
  computed: {
    ...mapGetters('documentItems', ['getDocumentItemsByParent']),
    ...mapState('documentItems', ['childDocumentItems']),
    termTypeIcon() {
      const termTypeObject = this.genericTypes.termTypes[String(this.termType).toLowerCase()]

      if (!termTypeObject) { return null }

      return termTypeObject.icon
    },
    termTypeId() {
      const termTypeObject = this.genericTypes.termTypes[String(this.termType).toLowerCase()]

      if (!termTypeObject) { return null }

      return termTypeObject.id
    }
  },
  watch: {
    search (val) {
      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      DocAdmin.terms.list({ 
        params: {
          search: this.search,
          termtypeid: this.termTypeId,
          limit: 10
        }
      }).then((res) => {
        const { count, results } = res.data

        this.count = count
        this.items = results
      }).catch((err) => {
        console.log(err)
      }).finally(() => (this.isLoading = false))
    },
    select (val, prev) {
      if (val.length === prev.length) return
      if (val.length < prev.length) return

      const newItem = JSON.parse(JSON.stringify(val)).pop()

      this.addItem(newItem)
      
      return

    }
  },
  mounted() {
    this.getConnectedTerms()
  },
  methods: {
    async getConnectedTerms() {
      if (!this.sourceId) { return }
      this.isLoading = true

      const qparams = {
        sourceid: this.sourceId,
        source_connectiontypeid: this.sourceConnectionTypeId,
        termid__termtypeid_id: this.termTypeId
      }

      const termResults = await DocAdmin.termRelationships.list({ params: qparams })

      if (termResults.data.results.length > 0) {
        termResults.data.results.forEach((a) => {
          a.termid.termrelationshipid = a.termrelationshipid
          this.select.push(a.termid)
        })
      }

      this.isLoading = false
    },
    async addItem (selectedTerm) {
      this.isLoading = true

      const qparams = {
        sourceid: this.sourceId,
        source_connectiontypeid_id: this.sourceConnectionTypeId,
        termid_id: selectedTerm.termid
      }

      DocAdmin.termRelationships.create(qparams).then((r) => {
        // selectedTerm.termrelationshipid = r.termrelationshipid
        this.select[this.select.length - 1].termrelationshipid = r.termrelationshipid
      }).finally(() => {
        this.isLoading = false
      })
    },
    removeTermItemChip(term) {
      const foundIndex = this.select.findIndex((x) => x.termid === term.termid)

      if (foundIndex !== -1) {
        this.select.splice(foundIndex, 1)
      }
    },
    async removeItem (item) {
      if (!item.termrelationshipid) {
        console.error(`cannot remove item ${item.englishtitle}, as i dont know its termrelationshipid`)
        
        return
      } 

      this.isLoading = true
      await DocAdmin.termRelationships.remove(item.termrelationshipid)
      this.removeTermItemChip(item)
      this.isLoading = false
    },
    addNewTerm() {
      window.open(`${configs.application.docAdmin.baseUrl.replace('api', 'admin')}/app/terms/add/`, '_blank')
    },
    documentItemTypeName (typeId) {
      return types.getDocumentItemTypeNameById(typeId)
    }
  }
}
</script>