<template>
  <v-autocomplete
    :value="value"
    :items="dataStructures"
    :loading="loading"
    :label="label"
    :name="name"
    tile
    color="blue-grey lighten-2"
    item-text="name"
    item-value="datastructureid"
    :hint="hint"
    v-on="listeners"
    return-object
  >
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DataStructureLookupField',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Datastructure'
    },
    name: {
      type: String,
      default: 'datastructureid'
    },
    hint: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapState('documents', ['dataStructures']),
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => { 
          if (!e) { return }
          this.$emit("input", e)
        }
      }
    }
  },
  // watch: {
  //   value: {
  //     deep: true,
  //     immediate: true,
  //     handler: function (a) {
  //       if (isNaN(a)) {
  //         this.$emit("input", a)
  //       }
  //     }
  //   }
  // },
  created() {
    if (this.dataStructures && this.dataStructures.length > 0) { 
      this.loading = false
      return 
    }
    this.loadData()
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('documents/getDataStructures')
      this.loading = false
    }
  }
}
</script>