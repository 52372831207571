<template>
  <div>
    <v-progress-linear v-if="loading" :size="20" color="primary" indeterminate />
    <!-- we are doing a v-if here because we want to make sure structure is loading before any other components load -->
    <div v-if="document">
      <DocumentHeading />
      <router-view />
    </div>
    <NotFoundPage v-if="notfound" />
    <!-- <v-card tile flat v-else>
      <v-card-text>No document found with id={{ $route.params.documentid }}</v-card-text>
    </v-card> -->
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import DocumentHeading from '@/components/documents/DocumentHeading'
import NotFoundPage from '@/pages/error/NotFoundPage.vue'
import configs from '@/configs'

export default {
  name: 'DocumentLayout',
  components: {
    DocumentHeading,
    NotFoundPage
  },
  data: () => ({
    loading: true,
    notfound: false,
    connectionType: configs.connectionProperties.types
  }),
  computed: {
    ...mapState('documents', ['dataStructure', 'document', 'documentTranslations', 'documentAuthors']),
    ...mapState('languages', ['languages']),
    ...mapGetters('documents', ['getDocumentDataStructure'])
  },
  async created () {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      try {
        await this.getDocument()
        await this.getDataStructure()
        await this.getDocumentAuthors()
        await this.getDocumentTranslations()
        await this.getLanguages()
      } catch (err) {
        this.notfound = true
        console.error(`DocumentLayout.Error in loading data, error=${err}`)
      }
      this.loading = false
    },
    async getDataStructure() {
      
      if (this.document.datastructureid) {
        return
      }

      if (!this.document) {
        console.error('DocumentLayout.getDataStructure: document no found?')

        return
      }

      console.log('DocumentLayout.loading getDefaultDataStructure')
      await this.$store.dispatch('documents/getDefaultDataStructure')
    },
    async getDocumentTranslations() {
      const documentId = this.$route.params.documentid
      
      if (this.documentTranslations && this.documentTranslations.length && Number(this.documentTranslations[0].documentid.documentid) === Number(documentId)) {
        return
      }

      console.log(`DocumentLayout.loading documentTranslations with documentId=${documentId}`)
      await this.$store.dispatch('documents/getDocumentTranslations', documentId)
    },
    async getDocument() {
      const documentId = this.$route.params.documentid
      
      if (this.document && Number(this.document.documentid) === Number(documentId)) {
        return
      }
      
      console.log(`DocumentLayout.loading documentId=${documentId}`)
      
      await this.$store.dispatch('documents/getDocument', { 
        documentId: documentId, 
        loadTranslations: true
      })
    },
    async getDocumentAuthors() {
      const documentId = this.$route.params.documentid
      
      if (this.documentAuthors && this.documentAuthors.length && Number(this.documentAuthors[0].documentid.documentid) === Number(documentId)) {
        return
      }

      console.log(`DocumentLayout.loading documentAuthors with documentId=${documentId}`)
      await this.$store.dispatch('documents/getDocumentAuthors', documentId)
    },
    async getLanguages() {
      
      if (this.languages) {
        return
      }

      console.log('DocumentLayout.loading languages')
      await this.$store.dispatch('languages/getLanguages')
    }
  }
}
</script>