import { render, staticRenderFns } from "./DocumentTranslationListing.vue?vue&type=template&id=085f8635"
import script from "./DocumentTranslationListing.vue?vue&type=script&lang=js"
export * from "./DocumentTranslationListing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports