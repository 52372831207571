import configs from '@/configs'
import types from './types'

export default {
  /**
   * 
   * @param {String} documentTypeName 
   * @returns 
   */
  getDocumentTypeIcon(documentTypeName) {
    if (documentTypeName in types.documentTypes) {
      return types.documentTypes[documentTypeName].icon
    }

    return ''
  },

  /**
   * 
   * @param {String} termName 
   * @returns 
   */
  getTermTypeIcon(termName) {
    termName = termName.toLowerCase()
    if (termName in types.termTypes) {
      return types.termTypes[termName].icon
    }

    return ''
  },
  
  /**
   * Converts string to titlecase
   * 
   * @param {String} strData 
   * @returns 
   */
  titleCase(strData) {
    const sentence = strData.toLowerCase().split(' ')
    
    for (let i = 0; i < sentence.length; i ++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
    }

    return sentence[0]
  },


  // o.documentitemid.parentdocumentitemid.documentitemid ? o.documentitemid.parentdocumentitemid.documentitemid : o.documentitemid.documentitemid,
  // o.documentitemid.documentid.documentid,
  // o.documentitemid.breadcrumb,
  // 6,
  // o.languageid.languageid

  /**
   * Creates a URL from the breadcrumb
   * 
   * @param {Number} id
   * @param {Number} rootId
   * @param {String} breadcrumb
   * @param {Number} connectionTypeId
   * @param {Number} languageId
   * 
   * @returns {String}
   */
  generateUrlFromBreadcrumb(id, rootId, breadcrumb, connectionTypeId, languageId) {

    const ct = configs.connectionProperties.types

    if (breadcrumb) {
      try {
        breadcrumb = JSON.parse(breadcrumb)
  
        const leafNode = breadcrumb.find((bi) => bi.isleafnode)

        if (Number(connectionTypeId) === ct.documentItems) {
          return `/app/document/${breadcrumb[0].id}/documentitem/${leafNode.id}`
        }
  
        if (Number(connectionTypeId) === ct.documentItemTranslations) {
          let url = `/app/document/${breadcrumb[0].id}/documentitem/${leafNode.id}?documentitem_focus=${ct.documentItemTranslations.documentitemid}`
  
          if (languageId) { 
            url = `${url}&documentitemtranslation_languageid=${languageId}`
          }
  
          return url
        }
      } catch (err) {
        console.error(`helper.utils.createUrlFromBreadcrumb could not convert (${breadcrumb}) to a JSON ${err}`)
      }
    }
    
    if (Number(connectionTypeId) === ct.definitions) {
      return `/app/definition/${rootId}`
    }
    if (Number(connectionTypeId) === ct.persons) {
      return `/app/person/${rootId}`
    }
    if (Number(connectionTypeId) === ct.documentItems || Number(connectionTypeId) === ct.documentItemTranslations) {
      let url = `/app/document/${rootId}/documentitem/${id}`

      if (languageId) { 
        url = `${url}?documentitemtranslation_languageid=${languageId}`
      }

      return url
    }

    return '#'
  },

  /**
   * 
   * @param {*} connectionTypeId 
   * @param {*} breadcrumb 
   * @param {*} connectionTitle 
   * @returns 
   */
  generateTitleFromBreadcrumb(connectionTypeId, breadcrumb, connectionTitle, separator) {
    // console.log(`generateTitleFromBreadcrumb: connectionTypeId=${connectionTypeId}, breadcrumb=${breadcrumb}, connectionTitle=${connectionTitle}`)
    if (!breadcrumb) { return connectionTitle || '' }
    const ct = configs.connectionProperties.types

    if (Number(connectionTypeId) === ct.documentItemTranslations || Number(connectionTypeId) === ct.documentItems && breadcrumb) {
      return JSON.parse(breadcrumb).map((b) => b.title).join(separator || '/')
    }

    return connectionTitle
  },

  generateBreadcrumb(breadcrumb) {

  }
}