<template>
  <v-row v-if="view === 'cards'">
    <v-col
      v-for="(translation) of documentTranslations"
      :key="'document_manage_'+translation.id" 
      cols="12" 
      :md="columns"
    >
      <v-card 
        flat
        class="h-full d-flex flex-column"
        tile
      >
        <ImageManager 
          v-if="isEnableTranslationImage()"
          :id="translation.id"
          :connection-type-id="connectionType.documentTranslations" 
        />
        <v-badge
          :color="isDraftColor(translation)"
          :icon="isDraftIcon(translation)"
          overlap
        >
          <v-card-title v-if="translation.title">
            <div class="flex-grow-1 text-truncate">
              {{ translation.title }}
            </div>
          </v-card-title>
          <v-card-subtitle v-if="translation.subtitle">
            <div class="flex-grow-1 text-truncate">
              {{ translation.subtitle }}
            </div>
          </v-card-subtitle>
          <v-card-text v-if="translation.description" v-html="translation.description"></v-card-text>
          <div v-if="document.documenttypeid.name == 'ReadingPlan'" class="pa-2" color="primary">
            <v-icon color="green">mdi-sync</v-icon>
            <ReadingPlanSync
              text="Sync To CouchDB"
              :document-id="String(document.documentid)"
              :language-id="String(translation.languageid.languageid)"
            />
          </div>
        </v-badge>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col>
      <v-card flat tile v-if="documentTranslations && documentTranslations.length">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Title</th>
                <th class="text-left">Description</th>
                <th class="text-left">Language</th>
                <th class="text-left">Published</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="translation of documentTranslations"
                :key="translation.id"
              >
                <td>{{ translation.title }}</td>
                <td>{{ translation.description ? String(translation.description).substring(0, 50) + '...' : '' }}</td>
                <td>{{ translation.languageid.englishname }}</td>
                <td>
                  <v-icon dense :color="isDraftColor(translation)">{{ isDraftIcon(translation) }}</v-icon>
                </td>
                <td>
                  <v-btn-toggle tile borderless dense>
                    <v-btn @click="add"><v-icon dense color="primary">mdi-plus-circle-outline</v-icon></v-btn>
                    <v-btn @click="edit(translation.id)"><v-icon dense color="orange">mdi-pencil</v-icon></v-btn>
                    <v-btn @click="remove(translation.id)"><v-icon dense color="red">mdi-delete</v-icon></v-btn>
                  </v-btn-toggle>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card v-else class="text-center" tile flat>
        <v-card-text>
          <v-btn color="green" @click="add">Add Translation</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import ImageManager from '@/components/ImageManager'
import { mapState, mapGetters, mapActions } from 'vuex'
import configs from '@/configs'
import ReadingPlanSync from '@/components/adminSettings/ReadingPlanSync'

export default {
  name: 'DocumentTranslationListing',
  components: {
    ImageManager,
    ReadingPlanSync
  },
  props: {
    view: {
      type: String,
      default: 'cards'
    }
  },
  data() {
    return {
      connectionType: configs.connectionProperties.types
    }
  },
  computed: {
    ...mapGetters('documents', ['isEnableTranslationImage']),
    ...mapState('documents', ['documentTranslations', 'document']),
    columns() {
      if (this.documentTranslations.length >= 3) {
        return 4
      } else if (this.documentTranslations.length >= 2) {
        return 6
      }
      return 12
    }
  },
  methods: {
    ...mapActions('documents', ['deleteDocumentTranslation']),
    isDraftIcon(t) {
      return t.isdraft ? 'mdi-alpha-x-circle-outline' : 'mdi-check-circle-outline'
    },
    isDraftColor(t) {
      return t.isdraft ? 'red' : 'green'
    },
    add () {
      this.$router.push({ 
        name: 'document_translations_add'
      })
    },
    edit (id) {
      this.$router.push({ 
        name: 'document_translations_edit', 
        params: {
          documenttranslationid: id
        }
      })
    },
    async remove (id) {
      await this.deleteDocumentTranslation(id)
      this.$store.dispatch('app/setSnackBar', `Deleted translation id=${id}`)
    }
  }
}
</script>