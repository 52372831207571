<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" x-small v-on="on">
          <v-icon >{{ icon }}</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item v-for="(item, index) in menuItems" :key="index" color="primary" @click="handleClick(index)">
          <v-list-item-icon>
            <v-icon :color="item.color" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="document.documenttypeid.name == 'ReadingPlan'" color="primary">
          <v-list-item-icon>
            <v-icon color="green">mdi-sync</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <ReadingPlanSync
                :document-id="String(document.documentid)"
                text="Sync To CouchDB"
              />
            </v-list-item-title>    
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-menu>

    <!-- <ConfirmDialog 
      v-if="dialogs.delete" 
      :key="`cd-dim-${documentItem.documentitemid}`"
      :message="`Are you sure you want to delete ${documentItemTypeName} (${documentItem.documentitemid}) and all its translations?`"
      :title="`Delete ${documentItemTypeName}`"
      bar-color="red"
      @close="dialogs.delete = false"
      @delete="deleteDocumentItemAndTranslations(documentItem.documentitemid)"
    /> -->

    <JsonDialog 
      v-if="dialogs.raw"
      :key="`jd-dim-${document.documentid}`"
      :title="`Not sure yet? Object`"
      :text="document"
      @close="dialogs.raw = false"
    />
  </div>
</template>

<script>
import configs from '@/configs'
import types from '@/helpers/types'
import JsonDialog from '@/components/shared/JsonDialog'
import ConfirmDialog from '@/components/shared/ConfirmDialog'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import ReadingPlanSync from '@/components/adminSettings/ReadingPlanSync'

export default {
  name: 'DocumentMenu',
  components: {
    ConfirmDialog,
    JsonDialog,
    ReadingPlanSync
  },
  props: {
    icon: {
      type: String,
      default: 'mdi-cog-outline'
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    dialogs: {
      raw: false,
      delete: false,
      translationForm: false
    },
    menuItems: [
      {
        id: 'edit-document',
        title: 'Edit',
        icon: 'mdi-pencil-outline',
        color: 'blue',
        click() {
          this.$router.push({ 
            name: 'document_edit'
          })
        }
      },
      {
        title: 'Add Translation',
        icon: 'mdi-plus',
        color: 'blue',
        click() {
          this.$router.push({ 
            name: 'document_translations_add', 
            params: {
              documentid: this.document.documentid 
            }
          })
        }
      },
      {
        title: 'Raw Data',
        icon: 'mdi-code-json',
        color: 'blue',
        click() {
          this.dialogs.raw = true
        }
      },
      {
        id: 'delete-document-item-type',
        title: undefined,
        icon: 'mdi-delete',
        color: 'red',
        click() {
          this.dialogs.delete = true
        }
      }
    ]
  }),
  computed: {
    ...mapState('documents', ['document', 'dataStructure']),
    ...mapGetters('documents', ['getDocumentDataStructure', 'selectedLanguagesByDocument', 'getDocumentItemDataStructureById'])
    // documentTypeName() {
    //   return types.getDocumentTypeNameById(this.documentItem.documentitemtypeid)
    // }
  },
  watch: { },
  mounted() {
  },
  methods: {
    handleClick(index) {
      this.menuItems[index].click.call(this)
    }
    // async deleteDocumentItemAndTranslations(id) {
    //   await this.$store.dispatch('documentItems/deleteDocumentItemsWithChildren', id)
    //   this.dialogs.delete = false
    //   this.$emit('close')
    // }
  }
}
</script>